.vertical-container-of-button-on-iframe {
  opacity: 0.5;
  position: absolute;
  float: left;
  top: 20px;
  left: 20px;
  transition: 0.5s;
  width: 45px !important;
  padding-top: 6px;
  padding-bottom: 6px;
}

.vertical-container-of-button-on-iframe:hover {
  opacity: 1;
}

//.list-group-item:hover {
//  background-color: var(--bs-dark); /* Use Bootstrap primary color */
//  cursor: pointer;
//}
