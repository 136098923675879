.mgdfv-vertical-container-of-button-on-iframe {
  position: absolute;
  opacity: 0.5;
  float: left;
  top: 20px;
  left: 20px;
  transition: 0.5s;
  width: 45px !important;
  padding-top: 6px;
  padding-bottom: 6px;
  z-index: 9999;
}

.mgdfv-vertical-container-of-button-on-iframe:hover {
  opacity: 1;
}
