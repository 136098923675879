.js-calculationsResults {
  table-layout: fixed;
}

.js-calculationsResults th {
  font-weight: bold;
}

.js-calculationsResults, .js-calculationsResults td, .js-calculationsResults th {
  text-align: center;
  border: 1px solid #575962 !important;
  vertical-align: middle !important;
}

.js-calculationsResults .title_fixed_width {
  width: 159px !important;
}

.container-salaire {
  width: 100%;
  @media (max-width: 767px) {
    background-color: red;
    max-width: 767px;
    overflow-x: scroll;
  }
}

