/*Table head*/
.iSAVrt{
  [data-theme="dark"] & {
    background-color: var(--kt-modal-bg);
    color: white;
  }
}

/*Table body*/
.dwPlXY {
  [data-theme="dark"] & {
    background-color: var(--kt-modal-bg);
    color: white;
  }
}

/*Table footer*/
.gKbhqU{
  [data-theme="dark"] & {
    background-color: var(--kt-modal-bg);
    color: white;
  }
}

/*Footer previus & next page icon*/
.fyrdjl:enabled{
  [data-theme="dark"] & {
    fill: white;
  }
}

.fyrdjl:disabled{
  [data-theme="dark"] & {
    fill: #5E6278;
  }
}

/*Expand arrow*/
.eiPgcm{
  [data-theme="dark"] & {
    color: white;
  }
}

/*Expanded container*/
.chgTOl{
  [data-theme="dark"] & {
    background-color: var(--kt-modal-bg);
    color: white;
  }
}

/*Loading container*/
.BafWS{
  [data-theme="dark"] & {
    background-color: var(--kt-modal-bg);
    color: white;
  }
}

/*Aucun résultat container*/
.bzRnkJ{
  [data-theme="dark"] & {
    background-color: var(--kt-modal-bg);
    color: white;
  }
}



