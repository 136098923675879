//
// Vendors customization
//

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// Initialize
@import "init";

// Plugins
@import "./core/vendors/plugins/plugins";
@import 'react-toastify/dist/ReactToastify.css';
@import "react-datepicker/dist/react-datepicker.css";