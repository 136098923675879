.vertical-container-of-buttons-on-iframe {
  opacity: 0.5;
  position: absolute;
  float: left;
  top: 20px;
  left: 20px;
  transition: 0.5s;
  width: 45px !important;
  padding-top: 6px;
  padding-bottom: 6px;
}

.vertical-container-of-buttons-on-iframe:hover {
  opacity: 1;
}
