.split {
  display: flex;
  flex-direction: row;
}

.gutter {
  [data-theme="light"] & {
    background-color: rgb(0,0,0, 0.5);
  }
  [data-theme="dark"] & {
    background-color: rgb(255, 255, 255, 0.5);
  }
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}

.split-horizontal {
  display: flex;
  width: 100%;
}
.split-vertical {
  display: flex;
  flex-direction: column;
}

.gutter:hover {
  [data-theme="light"] & {
    background-color: rgb(0,0,0, 1);
  }
  [data-theme="dark"] & {
    background-color: rgb(255, 255, 255, 1);
  }
  cursor: col-resize;
}
