.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  [data-theme="light"] & {
    border: 1px solid var(--kt-gray-600);
  }
  [data-theme="dark"] & {
    border: 1px solid var(--kt-input-border-color);
  }
}

.custom-editor {
  height: 200px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 0.475rem!important;
}

