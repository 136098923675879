// BEGIN:: TEMPLATE REPLACEMENTS

// Sidebar menu
// -- Z-index
[data-kt-app-layout=dark-sidebar] .app-sidebar {
  z-index: 999;
}

// Confirm & cancel button SWAL2
.swal2-styled.swal2-cancel {
  min-width: 80px;
}

.swal2-styled.swal2-confirm {
  min-width: 80px;
  margin-right: 15px;
}

// input-sm = btn-sm height
.input-sm {
  padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
  font-size: 0.925rem;
  border-radius: 0.425rem
}

// Container max height
.swal2-container .swal2-html-container {
  max-height: 250px !important;
}


// Text color black for button warning (the white hurts eyes)
.btn.btn-warning {
  color: black !important;
}

.btn.btn-warning > * {
  color: black !important;
}

// No auto margin for button icons
.btn i {
  padding: 0;
  margin: 0;
  font-size: 1.40rem
}


// Left menu
.menu-sub-indention .menu-sub:not([data-popper-placement]) {
  margin-left: 2rem !important;
}


// Modal


// -- Modal header too big
.modal-header {
  [data-theme="light"] & {
    border: 1px rgb(0, 0, 0, 0.2) solid !important;;
    //border-top: 1px rgb(0,0,0, 0.2) solid;
    //border-left: 1px rgb(0,0,0, 0.2) solid;
  }

  [data-theme="dark"] & {
    border: 1px rgb(255, 255, 255, 0.2) solid !important;
    //border-top: 1px rgb(255,255,255, 0.2) solid;
    //border-left: 1px rgb(255,255,255, 0.2) solid;
  }

  padding: 1.25rem 1.25rem !important;
}

.modal-body {
  [data-theme="light"] & {
    border: 1px rgb(0, 0, 0, 0.2) solid !important;;
    //border-top: 1px rgb(0,0,0, 0.2) solid;
    //border-left: 1px rgb(0,0,0, 0.2) solid;
  }

  [data-theme="dark"] & {
    border: 1px rgb(255, 255, 255, 0.2) solid !important;;
    //border-top: 1px rgb(255,255,255, 0.2) solid;
    //border-left: 1px rgb(255,255,255, 0.2) solid;
  }
}


// Card

// -- Padding header
.card .card-header {
  padding: 0 1rem;
}

// END:: TEMPLATE REPLACEMENT


// BEGIN:: CUSTOM

// -- Animations
.animation-when-something-becomes-visible {
  display: block;
  animation: fadeIn 1.5s ease; /* Pulse animation for 2 seconds */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

// -- Cursors
.cursor-not-allowed {
  cursor: not-allowed !important;
}

// Inputs
// -- Remove the increment and decrement arrows in number input
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-clear-button,
input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
}

// --- For Firefox
input[type="number"] {
  -moz-appearance: textfield;
}


// MAKE SOMETHING FULLSCREEN
.full-screen {
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  position: fixed;
}


// Hover border
.border-hover-primary:hover {
  border: 1px solid var(--kt-primary);
}

// END:: CUSTOM
