.preview-height {
  height: 68vh;
  overflow: auto;
  width: 100%;

  /* On <= LG*/
  @media (max-width: 1199px) {
    height: 80vh;
    overflow: auto;
  }
}


.nav-tabs-custom > li {
  writing-mode: vertical-lr;
  transform: rotate(360deg);
}

.vertical-container-of-button-on-iframe {
  opacity: 0.5;
  position: absolute;
  float: left;
  top: 20px;
  left: 20px;
  transition: 0.5s;
  width: 45px !important;
  padding-top: 6px;
  padding-bottom: 6px;
}

.vertical-container-of-button-on-iframe:hover{
  opacity: 1;
}


